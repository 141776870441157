
<template>
	<div class="h-screen flex w-full bg-img"> 
	</div>
	
</template>

<script>  
export default {
	data() {
		return { 
			checkBox1: false
		}
	},
	methods: { 
			async logout(){ 
				localStorage.clear();
				this.$router.push('/dashboard') ;  
			}
	},
	mounted() {
		this.logout();
	}

}
</script>